// pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { SignIn, useUser } from '@clerk/clerk-react'

const FloatingIcons = () => {
  const isDesktop = window.innerWidth >= 992;
  // Define the icons and their initial positions
  const iconPositionsWeb = [
    { left: '-2%', top: '22%', delay: '0s', icon: '/icons/cil_money.png' },
    { left: '45%', top: '-3%', delay: '0.5s', icon: '/icons/lineicons_investment.png' },
    { left: '37%', top: '47%', delay: '1s', icon: '/icons/healthicons_money-bag-outline.png' },
    { left: '95%', top: '106%', delay: '1.5s', icon: '/icons/lineicons_investment.png' },
    { left: '55%', top: '92%', delay: '2s', icon: '/icons/cil_money.png' },
    { left: '12%', top: '82%', delay: '2.5s', icon: '/icons/grow.png' },
    { left: '96%', top: '-18%', delay: '3s', icon: '/icons/grow.png' },
  ];
  // Define the icons and their initial positions
  const iconPositionsMobile = [
    { left: '6%', top: '10%', delay: '0s', icon: '/icons/cil_money.png' },
    { left: '82%', top: '10%', delay: '0.5s', icon: '/icons/lineicons_investment.png' },
    { left: '2%', top: '26%', delay: '1s', icon: '/icons/healthicons_money-bag-outline.png' },
    { left: '45%', top: '90%', delay: '1.5s', icon: '/icons/lineicons_investment.png' },
    { left: '82%', top: '26%', delay: '2s', icon: '/icons/cil_money.png' },
    { left: '12%', top: '90%', delay: '2.5s', icon: '/icons/grow.png' },
    { left: '75%', top: '90%', delay: '3s', icon: '/icons/grow.png' },
  ];

  const iconPositions = isDesktop ? iconPositionsWeb : iconPositionsMobile
  return (
    <div className="pointer-events-none">
      {iconPositions.map((position, index) => (
        <div
          key={index}
          className="animate-float opacity-20"
          style={{
            position: "absolute",
            left: position.left,
            top: position.top,
            animationDelay: position.delay,
          }}
        >
          <img height={50} src={position.icon} alt={`icon+${index}`} />
        </div>
      ))}

      <style jsx="true" global>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-20px);
          }
        }

        .animate-float {
          animation: float 6s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { user } = useUser()

  if (user) {
    navigate('/dashboard');
  }
  
  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center bg-purple bg-img-position">
      <div className="container">
        <FloatingIcons />
        <div className="row justify-content-between align-items-center">
          <div className="logoMain">
            <img src="/logo.png" width="210px" alt="logo" />
          </div>
          <div className="col-md-7">
            <h1 className="text-white display-4 mb-4 custom-heading">
              Our Best <br />
              <span className="underline">Investment</span> Will <br />
              Always Be Our Investors
            </h1>
          </div>
          <div className="col-md-5 customWidth">
            <SignIn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;