// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react'

const PrivateRoute = ({ children }) => {
  const { user } = useUser()
  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;